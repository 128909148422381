<template>
    <div class="project_check_list">
        <el-card>
            <nav class="input" style="width: auto">
                <el-input v-model="search.keyword" size="medium" placeholder="请输入项目名称" />
            </nav>
            <nav class="input" style="width: 150px">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="$refs.table.reload()">{{ "搜索" }}</el-button>
            </nav>
            <!-- <nav class="input" style="width: auto">
                <router-link to="/sms_template_list_add">
                    <el-button type="primary" size="small">{{ "创建模板" }}</el-button>
                </router-link>
            </nav> -->
        </el-card>
        <el-card style="margin-top: 10px">
            <z-table ref="table" v-model="list" @query="queryList" fullscreenLoading>
                <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                    <el-table-column prop="id" label="ID"> </el-table-column>
                    <el-table-column prop="name" label="项目名称"></el-table-column>
                    <el-table-column prop="logo" label="LOGO">
                        <template #default="{row}">
                            <p-image :src="row.logo"></p-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="desc" label="项目简介" :show-overflow-tooltip="true" width="220"> </el-table-column>
                    <el-table-column prop="business" label="行业">
                        <template slot-scope="{ row }">
                            <div class="tag" v-for="(item, index) in row.business" :key="index">
                                <el-tag size="small">{{ item }}</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="city" label="省市">
                        <template slot-scope="{ row }">
                            <div class="view-city">
                                <span v-if="row.province">{{ row.province }}</span>
                                <span v-if="row.city">{{ row.city }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="people" label="规模"> </el-table-column>
                    <el-table-column prop="status" label="认领人">
                        <template slot-scope="{ row:{user} }">
                            <div>
                                <p-image width="40px" :src="user.avatar"></p-image>
                            </div>
                            <div>
                                {{ user.realName || user.nickname }}
                            </div>
                            <div class="view-city">
                                <span v-if="user.company">{{ user.company }}</span>
                                <span v-if="user.job">{{ user.job }}</span>
                            </div>
                           
                        </template>
                    </el-table-column>

                    <el-table-column prop="claimTime" label="认证创建时间"> </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <el-popconfirm @confirm="approved(row, 0)" title="是否继续操作">
                                <el-button slot="reference" type="text" icon="el-icon-s-check" size="mini"
                                    >审核通过</el-button
                                >
                            </el-popconfirm>
                            <el-button
                                @click="auditNotThrough(row)"
                                slot="reference"
                                type="text"
                                icon="el-icon-s-check"
                                style="color: red;"
                                size="mini"
                                >审核不通过</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </z-table>
        </el-card>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
export default {
    mixins: [list],
    data() {
        return {
            search: {
                keyword: "",
            },
        };
    },
    activated() {
        // console.log(1);
    },
    methods: {
        getlist() {
            this.$refs.table.refresh();
        },

        async queryList(val) {
            let { data: res } = await this.$http.get("/admin/Finance/getProjectClaimList", {
                params: { ...val, ...this.search },
            });
            if (res.errorCode == 200) {
                // this.$message.success(res.message);
                this.$refs.table.complete(res.data);
            }
        },
        onEdit(row) {
            this.$router.push({ name: "sms_template_list_add", query: { id: row.id } });
        },
        async approved(row) {
            let { data: res } = await this.$http.post("/admin/Finance/projectClaim", { id: row.id, status: 1 });
            if (res.errorCode == 200) {
                this.$refs.table.refresh();
            }
        },
        async auditNotThrough(row) {
            var res = await this.$prompt("请输入不通过原因", "提示");
            var { data: res } = await this.$http.post("/admin/Finance/projectClaim", {
                id: row.id,
                status: 2,
                failReason: res.value,
            });
            this.$refs.table.refresh();
        },
        async onDelete(row) {
            await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            });
            let { data: res } = await this.$http.post("/admin/Sms/delTemplate", row);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        Ftag(val) {
            if (val == 0) {
                return "待审核";
            }
            if (val == 1) {
                return "审核通过";
            }
            if (val == 2) {
                return "审核不通过";
            }
            return "";
        },
    },
};
</script>

<style lang="less" scoped>
.tag {
    display: inline-block;
    margin: 5px;
}
</style>
