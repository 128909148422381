var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project_check_list"},[_c('el-card',[_c('nav',{staticClass:"input",staticStyle:{"width":"auto"}},[_c('el-input',{attrs:{"size":"medium","placeholder":"请输入项目名称"},model:{value:(_vm.search.keyword),callback:function ($$v) {_vm.$set(_vm.search, "keyword", $$v)},expression:"search.keyword"}})],1),_c('nav',{staticClass:"input",staticStyle:{"width":"150px"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.reset}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$refs.table.reload()}}},[_vm._v(_vm._s("搜索"))])],1)]),_c('el-card',{staticStyle:{"margin-top":"10px"}},[_c('z-table',{ref:"table",attrs:{"fullscreenLoading":""},on:{"query":_vm.queryList},model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"border":"","stripe":"","header-cell-style":_vm.rowClass}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"name","label":"项目名称"}}),_c('el-table-column',{attrs:{"prop":"logo","label":"LOGO"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p-image',{attrs:{"src":row.logo}})]}}])}),_c('el-table-column',{attrs:{"prop":"desc","label":"项目简介","show-overflow-tooltip":true,"width":"220"}}),_c('el-table-column',{attrs:{"prop":"business","label":"行业"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.business),function(item,index){return _c('div',{key:index,staticClass:"tag"},[_c('el-tag',{attrs:{"size":"small"}},[_vm._v(_vm._s(item))])],1)})}}])}),_c('el-table-column',{attrs:{"prop":"city","label":"省市"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"view-city"},[(row.province)?_c('span',[_vm._v(_vm._s(row.province))]):_vm._e(),(row.city)?_c('span',[_vm._v(_vm._s(row.city))]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"people","label":"规模"}}),_c('el-table-column',{attrs:{"prop":"status","label":"认领人"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var user = ref.row.user;
return [_c('div',[_c('p-image',{attrs:{"width":"40px","src":user.avatar}})],1),_c('div',[_vm._v(" "+_vm._s(user.realName || user.nickname)+" ")]),_c('div',{staticClass:"view-city"},[(user.company)?_c('span',[_vm._v(_vm._s(user.company))]):_vm._e(),(user.job)?_c('span',[_vm._v(_vm._s(user.job))]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"claimTime","label":"认证创建时间"}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-popconfirm',{attrs:{"title":"是否继续操作"},on:{"confirm":function($event){return _vm.approved(row, 0)}}},[_c('el-button',{attrs:{"slot":"reference","type":"text","icon":"el-icon-s-check","size":"mini"},slot:"reference"},[_vm._v("审核通过")])],1),_c('el-button',{staticStyle:{"color":"red"},attrs:{"slot":"reference","type":"text","icon":"el-icon-s-check","size":"mini"},on:{"click":function($event){return _vm.auditNotThrough(row)}},slot:"reference"},[_vm._v("审核不通过")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }